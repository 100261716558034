<script setup>
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import moment from 'moment/moment'

const { locale } = useI18n()
const { isDesktop } = useIsDesktop()
const maxDate = ref(null)

const model = defineModel()
const props = defineProps({
  availableDepartureDate: {
    type: Array,
  },
})
const selectedMonth = ref()

if (Array.isArray(model.value)) {
  selectedMonth.value = [
    {
      month: moment(model.value[0]).month(),
      year: moment(model.value[0]).year(),
    },
    {
      month: moment(model.value[1]).month(),
      year: moment(model.value[1]).year(),
    },
  ]
}

const updateModelDate = (modelData) => {
  if (!Array.isArray(modelData)) {
    modelData = [modelData, modelData]
  }

  selectedMonth.value = [
    {
      month: moment(modelData[0]).month(),
      year: moment(modelData[0]).year(),
    },
    {
      month: moment(modelData[1]).month(),
      year: moment(modelData[1]).year(),
    },
  ]
}

const updateModelMonth = (modelData) => {
  if (modelData[1] === null) {
    model.value = [
      moment(modelData[0]),
      moment(modelData[0]).endOf('month'),
    ]
  }
  else {
    model.value = [
      moment(modelData[0]),
      moment(modelData[1]).endOf('month'),
    ]
  }
}

const showMonth = ref(true)

onMounted(() => {
  const currentYear = new Date().getFullYear()
  maxDate.value = new Date(currentYear + 2, 11, 31)
})
</script>

<template>
  <div class="date-picker-container">
    <div class="date-picker-action">
      <button
        class="month"
        @click="showMonth = !showMonth"
      >
        <span
          :class="{
            current: showMonth,
          }"
        >
          {{ $t('filter.date.month') }}
        </span>
        <span
          :class="{
            current: !showMonth,
          }"
        >
          {{ $t('filter.date.day') }}
        </span>
      </button>
    </div>
    <div class="date-picker-content">
      <VueDatePicker
        v-if="!showMonth"
        v-model="model"
        :locale="locale"
        :enable-time-picker="false"
        inline
        auto-apply
        range
        :multi-calendars="{ count: isDesktop ? 3 : 0 }"
        :min-date="new Date()"
        :max-date="maxDate"
        :allowed-dates="availableDepartureDate"
        :model-auto="true"
        @update:model-value="updateModelDate"
      />
      <VueDatePicker
        v-else
        v-model="selectedMonth"
        :locale="locale"
        :enable-time-picker="false"
        month-picker
        inline
        auto-apply
        range
        :multi-calendars="{ count: isDesktop ? 3 : 0 }"
        :min-date="new Date()"
        :max-date="maxDate"
        :model-auto="true"
        @update:model-value="updateModelMonth"
      />
    </div>
  </div>
</template>

<style lang="scss">
:root {
  --dp-font-family: var( --font-primary );
}

.dp__theme_light {
  --dp-primary-color: var( --color-navy-450 );
  --dp-hover-color: var( --color-navy-10 );
  --dp-primary-text-color: white;
  --dp-text-color: var(--color-grey-550 );
  --dp-hover-text-color:  var(--color-grey-550 );
}

.date-picker-container {

  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;

  .date-picker-action {
    width: 100%;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      width: 20rem;
    }

    button {
      border: none;
      background: none;
      display: flex;
      flex-direction: column;
      width: 100%;

      @media (min-width: map-get($grid-breakpoints, lg)) {
        flex-direction: row;
      }

      span {
        background-color: var( --color-navy-10 );
        border: 1px solid var( --color-navy-10 );
        padding: 0.8rem 1.5rem;
        color: var( --color-grey-550 );
        font-size: 1rem;
        font-family: var(--font-primary);
        width: 100%;

        @media (min-width: map-get($grid-breakpoints, lg)) {
          width: 50%;
        }

        &.current {
          background-color: var( --color-white );
          border-color: var( --color-navy-450 );
        }
      }
    }
  }

  .date-picker-content {
    width: 100%;

    .dp__main {
      width: 100%;

      .dp__menu {
        border: none;
      }

      > div:last-of-type {
        width: 100%;
      }
    }

    .dp__overlay {
      width: 100%!important;

      &_container {
        padding: 0 1em;

        &:after {
          content: "";
          width: 1px;
          position: absolute;
          height: 50%;
          top: 35%;
          right: 0;
          background: var( --color-primary );
        }
      }
    }

    .dp__instance_calendar {
      &[role="document"] {
        color: red;

        .dp__instance_calendar {
          position: relative;

          &:first-of-type {
            &:after {
              content: "";
              width: 1px;
              position: absolute;
              height: 50%;
              top: 35%;
              right: 0;
              background: var(--color-primary);
            }
          }

          &:last-of-type {
            &:before {
              content: "";
              width: 1px;
              position: absolute;
              height: 50%;
              top: 35%;
              left: 0;
              background: var( --color-primary );
            }
          }
        }
      }
      .dp__overlay_col {
        padding: 0;
      }

      .dp--year-select {
        font-family: var( --font-secondary );
        color: var( --color-navy-600 );
        font-size: 1.2rem;
        font-weight: 600;
      }

      .dp__overlay_cell {
        &.dp__overlay_cell_disabled {
          color: var( --color-navy-150 );
        }
      }

      .dp__overlay_cell_active {
        color: var( --color-white );
      }

      .dp__range_start, .dp__range_end {
        color: var( --color-white );
      }

      .dp__range_start, .dp__range_end, .dp__range_between, .dp__today, .dp__active_date, .dp__cell_inner:hover {
        border-radius: 50px;
      }

      .dp--header-wrap {
        margin-bottom: 1rem;

        .dp__month_year_wrap {
          .dp__month_year_select {
            font-family: var( --font-secondary );
            color: var( --color-navy-600 );
            font-weight: 600;
            font-size: 1.2rem;

            &:first-of-type {
              justify-content: end;
            }

            &:last-of-type {
              justify-content: start;
            }
          }
        }
      }

      .dp__calendar_header {
        .dp__calendar_header_item {
          color: var( --color-grey-450 );
          align-items: center;
          display: flex;
          justify-content: center;
          font-size: 0.8rem;
          text-transform: uppercase;
        }
      }

      .dp__calendar_header_separator {
        display: none;
      }

      .dp__cell_inner {
        margin: 0 auto;
      }
    }
  }
}

.departure-date-from-container {
  .filter-list.filter-list--horizontal .filter-content--list {
    grid-template-columns: unset;
  }
}
</style>
